<script>
import Layout from "../../layouts/main";
import { paymentnotificationMethods } from "@/state/helpers";
import axios from "axios";

const apiURL = process.env.VUE_APP_API + "paymentnotification/updateseen/";
export default {
  components: {
    Layout,
  },
  data() {
    return {};
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getPaymentNot({ id: this.Student.id });
  },

  computed: {
    paymentnotification() {
      return this.$store
        ? this.$store.state.paymentnotification.PaymentNotification
        : null;
    },
  },
  watch: {
    paymentnotification(val) {
      if (val != null) {
        val.forEach((el) => {
          if (el.seen == false || el.seen == 0) {
            axios.patch(apiURL + el.id);
          }
        });
      }
    },
  },
  methods: {
    ...paymentnotificationMethods,
  },
};
</script>
<template>
  <layout>
    <b-col>
      <b-row class="mt-4" v-for="notif in paymentnotification" :key="notif.id">
        <b-col>
          <b-row style="margin:0px;padding:0px;">
            <b-col>
              <b-card
                style="background-color:darkred;color:white;border-radius:15px;display: flex;padding:10px;margin:0px;"
                no-body
              >
                <b-col sm="12" md="12" style="display: flex;">
                  <div
                    style="background-color:white;border-radius:10px; color:black;height:80px;width:80px;max-width:80px;padding:15px;font-size:30px;display: flex;margin-right:20px;"
                  >
                    {{ notif.type }}
                  </div>
                  <div>
                    <h5
                      style="color:white; line-height: 1.6;"
                      v-if="notif.type == 'Not'"
                    >
                      <b>Notification de paiement</b>
                    </h5>
                    <h5 style="color:white; line-height: 1.6;">
                      {{ notif.message }}
                    </h5>

                    <h6 style="color:white;">
                      {{ notif.date.substring(0, 10) }}
                      {{ notif.date.substring(11, 16) }}
                    </h6>
                  </div>
                </b-col>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </layout>
</template>
